import $ from "jquery";
import "popper.js"; // Bootstrap requires this
import "bootstrap";
import "fslightbox";
import "slick-carousel";
// Can import modules as needed
// ex. import 'bootstrap/js/dist/dropdown';
// import "./scss/style.scss"; // This is now being imported in the webpack.config file

// This is for slick
global.jQuery = global.$ = require('jquery');

// # ---------------------------------------------------------------
// # Custom Methods...
(function ($) {
  $(function () {
    // Utility function to check if element is visible
    function isVisible($el) {
      var winTop = $(window).scrollTop();
      var winBottom = winTop + $(window).height();
      var elTop = $el.offset().top;
      var elBottom = elTop + $el.height();
      return elBottom <= winBottom && elTop >= winTop;
    }

    // Utility function to format the number, so it will be seperated by comma
    function commaSeparateNumber(val) {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
      return val;
    }

    // Document is ready
    $(document).ready(function () {
      // Init Functions
      let initFunctions;
      initFunctions = function () {
        return {
          init: function () {
            this.initLogoSlider();
            this.initPostsSlider();
          },
          // ---------------------------------------------------------------
          initLogoSlider: function () {
            return $(document).ready(function () {
              return $(".slick-logos-slider").slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                prevArrow:
                  '<a class="slick-prev"><i class="bi bi-caret-left-square-fill"></i></a>',
                nextArrow:
                  '<a class="slick-next"><i class="bi bi-caret-right-square-fill"></i></i></a>',
                arrows: true,
                //appendArrows: ".slider-nav",
                dots: false,
                autoplay: true,
                autoplaySpeed: 10000,
                responsive: [
                  {
                    breakpoint: 800,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 1,
                    },
                  },
                  {
                    breakpoint: 500,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1,
                    },
                  },
                ],
              });
            });
          },
          // ---------------------------------------------------------------
          initPostsSlider: function () {
            return $(document).ready(function () {
              return $(".slick-posts-slider").slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                prevArrow:
                  '<a class="slick-prev"><i class="bi bi-caret-left-fill"></i></a>',
                nextArrow:
                  '<a class="slick-next"><i class="bi bi-caret-right-fill"></i></i></a>',
                arrows: true,
                //appendArrows: ".slider-nav",
                dots: false,
                autoplay: true,
                autoplaySpeed: 10000,
              });
            });
          },
          // ---------------------------------------------------------------
        };
      };

      // ---------------------------------------------------------------
      initFunctions().init();
      //-----------------------------

      // $("form.search-form input[type=search]").change(function () {
      //   console.log("test");
      //   $(".search-submit").removeClass("d-none");
      // });

      // Focus on search input when modal is opened
      $("#searchModal").on('shown.bs.modal', function () {
        $("#searchModal input").focus()
      })


      // $(window).scroll(function () {
      // 	var scrolledY = $(window).scrollTop();
      // 	$('.parallax').css('background-position', 'left ' + ((scrolledY)) + 'px');
      // });

      // Add a class to body on navbar toggle
      $(".navbar-toggler").click(function () {
        $("body").toggleClass("nav-open");
      });

      // Toggle share icons

      // TODO: 
      // Issue because of Ajax post filters
      // $(".share-button").click(function () {
      // $(document).on("click", '.share-button', function (event) {
      //   console.log('test');
      //   $(this).toggleClass("active");
      //   let wrapperID = $(this).data('target');
      //   // $("div#" + wrapperID).toggleClass("d-none");
      //   $(this).siblings('#' + wrapperID).toggleClass("d-none");
      // });


      // News Page Start with seminar series selected 
      $(".js-filter[data-term='seminar-series']").trigger("click");


      // Show back to top on scroll
      $(window).scroll(function () {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 20) {
          $("button#back-to-top").addClass('showing');
        } else {
          $("button#back-to-top").removeClass('showing');
        }
      });

      $("button#back-to-top").click(function () {
        $(window).scrollTop(0);
      });

      // Show back to BVRC on scroll
      $(window).scroll(function () {
        if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 20) {
          $("a#back-to-bvrc").addClass('showing');
        } else {
          $("a#back-to-bvrc").removeClass('showing');
        }
      });


      // Optimalisation: Store the references outside the event handler:
      // let $window = $(window);

      // function checkWidth() {
      //   let windowsize = $window.width();
      //   if (windowsize > 1200) {
      //     // if the window is smaller than 992, header menu parent items
      //     // will only toggle their dropdown menu
      //     $("ul#menu-primary li.menu-item-has-children a.dropdown-toggle").each(
      //       function () {
      //         $(this).removeAttr("data-bs-toggle");
      //       }
      //     );
      //   } else {
      //     $("ul#menu-primary li.menu-item-has-children a.dropdown-toggle").each(
      //       function () {
      //         $(this).attr("data-bs-toggle", "dropdown");
      //       }
      //     );
      //   }
      // }
      // Execute on load
      //checkWidth();
      // Bind event listener
      //$(window).resize(checkWidth);
    });
  });
})($);
